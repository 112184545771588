.searchAPA .container.centre {

    width : 100% !important;
}

.searchAPA .address{
    text-align: center;
    background: var(--neutral-grey, #f9f9f9);
    border-radius: 5px;
    margin-bottom: 50px;
    padding: 50px;
}

.searchAPA .address .form .btn{
    margin-top: 25px;
    width: 170px;
}

.leaflet-container{
    height: 500px;
    width: 100%;
}

.css-b62m3t-container {
    width: 500px;
    margin: auto;
    margin-top: 50px;
}

.resultNotFound{
    font-size: 20px;
    text-align: center;
    background: #f9f9f9;
    padding: 15px;
}

.slider{
    width: 500px;
    overflow: hidden;
    padding: 5px 5px;
    margin: auto;
    margin-top: 50px;
}

.slider input{
    appearance: none;
    -webkit-appearance: none;
    width: 500px;
    height: 17x;
    border-radius: 5px;
    border: 1px solid #ddd;
    outline: none;
    box-shadow: 0 1px 1px #fff, inset 0 1px 1px #ddd;
}

.slider input::-webkit-slider-thumb{
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    box-shadow: 0 0 2px #ddd;
    background: #0d6efd;
    position: relative;
}

#tickmarks{
    padding: 0;
    margin: 0;
    list-style: none;
    height: 20px;
    padding-top: 10px;
    counter-reset: step -5;
}

#tickmarks li{
    width: 7.6%;
    float: left;
    height: 10px;
    position: relative;
    padding-left: 10px;
    box-sizing: border-box;
}

#tickmarks li:nth-child(1){
    margin-left: -16px;
}
#tickmarks li:nth-child(2){
    margin-left: 8px;
}
#tickmarks li:nth-child(3){
    margin-left: 5px;
}
#tickmarks li:nth-child(4){
    margin-left: 8px;
}
#tickmarks li:nth-child(5){
    margin-left: 17px;
}
#tickmarks li:nth-child(6){
    margin-left: 8px;
}
#tickmarks li:nth-child(7){
    margin-left: 9px;
}
#tickmarks li:nth-child(8){
    margin-left: 9px;
}
#tickmarks li:nth-child(9){
    margin-left: 9px;
}
#tickmarks li:nth-child(10){
    margin-left: 19px;
}

#tickmarks li:before{
    content: counter(step);
    counter-increment: step 5;
    font-size: 15px;
    position: absolute;
    top: -6px;
}
.rayon{
    font-weight: 500;
}

.form-control.searchaddress{
    width: 500px;
    margin: auto;
    margin-top: 40px;
}

.searchAPA h2{
    font-size: 36px;
    font-weight: 400;
    line-height: 46px;
    font-style: normal;
    color: var(--neutral-black, #2C2C2C);
}

.p-3.mb-3.sapa{
    height: 127px;
}

.react-confirm-alert-button-group > button {
    background: #0d6efd !important;
    width: 150px !important;;
    margin: auto !important;;
}

.react-confirm-alert-button-group > .logout-no{
    background: white !important;
    color: #0d6efd;
    border: 1px solid #0d6efd;
}
.message{
    display: none;
}