.prescription{
    width: 750px;
    border: 1px solid #80808021;
    padding: 33px;
    border-radius: 14px;   
}
.prescription p{
    color: gray;
}

.check{
    border: 1px solid #80808021;
    padding: 10px;
    border-radius: 14px;  
    width : 750px;
    margin-top: 34px;
    display: flex;
}

.check strong{
 margin-left: 15px;
}

#editor{
    width: 500px;
}

.edit-save{
    position: absolute;
    right: 550px;
    margin-top: 5px;
    color: #0d6efd;
    font-weight: 500;
    cursor: pointer;
}

#prescription p:nth-child(1)
{
    margin-top: 25px;
}
@media (max-width: 1548px) {
    .edit-save{
        position: absolute;
        right: 247px;
        margin-top: 18px;
        color: #0d6efd;
        font-weight: 500;
        cursor: pointer;
        text-decoration: underline;
    }
}

@media (min-width: 1549px) {
    .edit-save {
        right: 622px;
        margin-top: 20px;
        text-decoration: underline;
    }
}

