.donnes-centre {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
}

.donnes-centre .div-2 {
  background-color: #ffffff;
  border: 1px none;
  height: 1080px;
  position: relative;
  width: 1920px;
}

.donnes-centre .steps-instance {
  left: 120px !important;
  position: absolute !important;
  top: 104px !important;
  width: 356px !important;
}

.donnes-centre .design-component-instance-node {
  overflow: hidden !important;
}

.donnes-centre .steps-2 {
  margin-right: -49px !important;
}

.donnes-centre .steps-3 {
  margin-right: -47.68px !important;
}

.donnes-centre .steps-4 {
  background-image: url(../../../static/img/icon-location-location.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-centre .steps-5 {
  background-image: url(../../../static/img/icon-essential-tick-circle-2.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-centre .steps-6 {
  left: 120px !important;
  position: absolute !important;
  top: 298px !important;
  width: 356px !important;
}

.donnes-centre .steps-7 {
  background-image: url(../../../static/img/icon-essential-tick-circle.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-centre .steps-8 {
  left: 120px !important;
  position: absolute !important;
  top: 401px !important;
  width: 356px !important;
}

.donnes-centre .h-1 {
  color: var(--neutralblack);
  font-family: var(--heading-1-desktop-font-family);
  font-size: var(--heading-1-desktop-font-size);
  font-style: var(--heading-1-desktop-font-style);
  font-weight: var(--heading-1-desktop-font-weight);
  left: 545px;
  letter-spacing: var(--heading-1-desktop-letter-spacing);
  line-height: var(--heading-1-desktop-line-height);
  position: absolute;
  top: 106px;
  white-space: nowrap;
}

.donnes-centre .p {
  color: var(--neutraldark-grey);
  font-family: var(--body-2-bold-font-family);
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  height: 21px;
  left: 545px;
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
  position: absolute;
  top: 166px;
  white-space: nowrap;
}

.donnes-centre .centre-instance {
  left: 545px !important;
  position: absolute !important;
  top: 214px !important;
}

.donnes-centre .centre-2 {
  left: 545px !important;
  position: absolute !important;
  top: 390px !important;
}

.donnes-centre .centre-3 {
  left: 545px !important;
  position: absolute !important;
  top: 567px !important;
}

.donnes-centre .bottom-buttons-instance {
  left: 545px !important;
  position: absolute !important;
  top: 833px !important;
}

.donnes-centre .bottom-buttons-2 {
  left: 1035px !important;
  position: absolute !important;
  top: 21px !important;
}

.centre.centre-instance.active{
  border: 1px solid rgb(66 130 255 / 68%);
  cursor: pointer;
}
.centre.centre-instance{
  cursor: pointer;
}

.centre-button {
  position: absolute;
  top: 423px;
  /* left: 545px; */
  right: 105px;
}
.container.centre.active{
  border: 1px solid #0d6efd;
} 

.container.centre{
  margin-bottom: 20px
} 

.bottom-buttons.centre-button-continue{
  margin-right: 167px;
}

.donnes-centre .steps.in-progress.steps-6{
  top: 325px !important;
}

.donnes-centre .steps-8{
  top: 432px !important;
}

.donnes-centre .title-center{
  color: var(--neutral-black, #2C2C2C);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

.steps.scrolled {
  position: fixed !important;
}

.donnes-centre .steps-instance.scrolled {
  top: 38px !important;
}

.donnes-centre  .in-progress.steps-6.scrolled {
  top: 261px !important;
}
.donnes-centre .steps-8.scrolled {
  top: 373px !important;
}

@media (min-width: 1537px){
  .bottom-buttons.centre-button-continue {
    margin-right: 190px;
    margin-left: 9px;
}
.donnes-centre .centre-button-continue .col-md-12{
  margin-left: 25px;
}
}
@media (max-width: 1536px){
  .donnes-centre .div-2 {
    width: 1495px;
  }
  .bottom-buttons.centre-button-continue {
    margin-right: 121px;
    margin-left: 35px;
 }
 .donnes-centre .centre-button-continue .col-md-12{
   margin-left: 25px;
 }

}
@media (max-width: 767px){
  .donnes-centre .div-2 {
  width: auto;
  height: auto;
}
}

@media (min-width: 950px) and (max-width: 1400px){
  .col-md-8.choice-center {
      margin-left: 489px !important;
  }
}
