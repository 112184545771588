.card-ajout-d-une {
  align-items: flex-end;
  background-color: var(--neutralwhite);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 247px;
  overflow: hidden;
  padding: 30px 20px;
  position: relative;
  width: fit-content;
}

.card-ajout-d-une .votre-prescription-a {
  color: var(--primary-1);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 300px;
}

.card-ajout-d-une .div {
  height: 101px;
  min-width: 300px;
  position: relative;
}

.card-ajout-d-une .ellipse {
  height: 65px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 65px;
}

.card-ajout-d-une .dr-jean-michel {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 40px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: -1px;
  width: 222px;
}

.card-ajout-d-une .text-wrapper-2 {
  color: #2b2b2b;
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.card-ajout-d-une .text-wrapper-3 {
  color: #aeaeae;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.card-ajout-d-une .design-component-instance-node {
  width: 299px !important;
  top: 20px;
}

.my-prescription{
cursor: pointer;
}

@media (min-width: 769px){
  .card-ajout-d-une {
    width: 416px;
  }
  .card-ajout-d-une .votre-prescription-a {
    width: 375px;
  }
  .card-ajout-d-une .div {
    min-width: 378px;
 }
 .card-ajout-d-une .design-component-instance-node {
  width: 379px !important;
}
} 

