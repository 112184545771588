.button-bubble {
  border: 1.5px solid;
  border-color: var(--primary-1);
  border-radius: 8px;
  height: 48px;
  overflow: hidden;
  width: 48px;
}

.button-bubble .icon-essential-close {
  background-image: url(../../../../static/img/icon-essential-close-circle-1.svg);
  background-size: 100% 100%;
  height: 24px;
  left: 12px;
  position: relative;
  top: 12px;
  width: 24px;
}

.button-bubble.property-1-default {
  background-color: var(--neutralwhite);
}

.button-bubble.property-1-variant-3 {
  background-color: var(--primary-1);
}

.button-bubble.property-1-variant-2 {
  background-color: var(--primary-1);
}
