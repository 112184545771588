.text-field-basic {
  border: 0px solid;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 243px;
}
.text-field-basic input{
border: 2px solid rgb(128 128 128 / 28%);
    border-radius: 8px;
    width: 243px;
    position: relative;
    width: 340px !important;
    overflow: hidden;
    height: 52px;
}

.text-field-basic .label {
  position: absolute;
}

.text-field-basic .text-wrapper {
  left: 8px;
  position: absolute;
}

.text-field-basic .option {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 57px;
  white-space: nowrap;
}

.text-field-basic .div {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 96px;
  white-space: nowrap;
}

.text-field-basic .option-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 133px;
  white-space: nowrap;
}

.text-field-basic .line {
  height: 1px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 242px;
}

.text-field-basic .img {
  height: 1px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  top: 88px;
  width: 242px;
}

.text-field-basic .line-2 {
  height: 1px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 242px;
}

.text-field-basic .icon-arrow-arrow {
  background-image: url(../../../../static/img/icon-arrow-arrow-down.svg);
  background-size: 100% 100%;
  height: 24px;
  left: 202px;
  position: absolute;
  top: 13px;
  width: 24px;
}

.text-field-basic .label-2 {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.text-field-basic.text-area {
  height: 124px;
}

.text-field-basic.initial {
  border-color: var(--neutralmedium-grey);
}

.text-field-basic.dropdown {
  border-color: var(--neutralmedium-grey);
}

.text-field-basic.filled {
  border-color: var(--neutralmedium-grey);
}

.text-field-basic.white {
  background-color: #ffffff;
}

.text-field-basic.transparent.text-area {
  border-color: var(--neutralmedium-grey);
}

.text-field-basic.filled.dropdown {
  height: 52px;
}

.text-field-basic.active.dropdown {
  height: 170px;
}

.text-field-basic.classic.active {
  border-color: var(--primary-1);
  height: 54px;
}

.text-field-basic.initial.dropdown {
  height: 52px;
}

.text-field-basic.classic.filled {
  height: 55px;
}

.text-field-basic.classic.initial {
  height: 52px;
}

.text-field-basic.active.white.text-area {
  border-color: var(--primary-1);
}

.text-field-basic.text-area .label {
  color: var(--primary-1);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  height: 11px;
  left: 8px;
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
  top: 6px;
  white-space: nowrap;
  width: 105px;
}

.text-field-basic.classic .label {
  color: var(--neutraldark-grey);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  height: 18px;
  left: 8px;
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
  top: 2px;
  white-space: nowrap;
}

.text-field-basic.dropdown .label {
  background-image: url(../../../../static/img/icon-arrow-arrow-down.svg);
  background-size: 100% 100%;
  height: 24px;
  left: 202px;
  top: 13px;
  width: 24px;
}

.text-field-basic.active .text-wrapper {
  color: var(--primary-1);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
  white-space: nowrap;
}

.text-field-basic.initial .text-wrapper {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  height: 24px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  top: 12px;
  white-space: nowrap;
}

.text-field-basic.dropdown .text-wrapper {
  height: 24px;
  top: 12px;
  white-space: nowrap;
}

.text-field-basic.filled .text-wrapper {
  color: var(--neutralblack);
  height: 24px;
}

.text-field-basic.classic .text-wrapper {
  white-space: nowrap;
}

.text-field-basic.filled.text-area .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  top: 20px;
  width: 225px;
}

.text-field-basic.filled.dropdown .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.text-field-basic.transparent.filled .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.text-field-basic.classic.active .text-wrapper {
  height: 18px;
  top: 2px;
}

.text-field-basic.active.text-area .text-wrapper {
  height: 11px;
  top: 6px;
  width: 105px;
}

.text-field-basic.classic.filled .text-wrapper {
  top: 20px;
}

.text-field-basic.classic.white.filled .text-wrapper {
  font-family: var(--label-initial-font-family);
  font-size: var(--label-initial-font-size);
  font-style: var(--label-initial-font-style);
  font-weight: var(--label-initial-font-weight);
  letter-spacing: var(--label-initial-letter-spacing);
  line-height: var(--label-initial-line-height);
}
