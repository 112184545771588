.react-calendar {
    margin: auto !important;
    margin-top: 50px !important;
    background: #a0a0960d !important;
    border: 1px solid #a0a0960d !important;
    border-radius: 10px;
    width: 350px;
    max-width: 100%;
    margin-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.col-6.session{
    border-radius: 8px;
    border: 1px solid var(--neutral-grey, #F9F9F9);
    margin-top: 50px;
}

.col-6.session p{
text-align: center;
}

.col-6.session p{
    text-align: center;
    color: var(--neutral-dark-grey, #AEAEAE);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-top: 25px;
}

.col-6.session p.done h5{
    color: var(--secondary-green, #00AEA4);
    text-align: center;
    /* Display 1 */
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

.col-6.session p.planified h5{
color: var(--primary-1, #4282FF);
text-align: center;
/* Heading 1 (desktop) */
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 46px;
}

.card-RDV-instance.calendar{
    border: 1px solid #006edc;
    margin: auto;
    margin-top: 50px;
}

.card-RDV-instance.calendar .maison-hygeia{
text-align: center;
}

.calendar-div{ 
    height: 910px;
}


.calendar-div .header-goal img{
cursor: pointer;
}

@media (min-width: 769px){
    .react-calendar {
        width: 407px;
        max-width: 100%;
    }
}
