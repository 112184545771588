.chat-message {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--neutralgrey);
    height: 108px;
    position: relative;
    width: 393px;
  }
  
  .chat-message .lorem-ipsum-dolor {
    color: var(--primary-2);
    font-family: var(--body-1-font-family);
    font-size: var(--body-1-font-size);
    font-style: var(--body-1-font-style);
    font-weight: var(--body-1-font-weight);
    left: 27px;
    letter-spacing: var(--body-1-letter-spacing);
    line-height: var(--body-1-line-height);
    position: absolute;
    top: 51px;
    width: 288px;
  }
  
  .chat-message .element {
    color: var(--neutralblack);
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 167px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    text-align: right;
    top: 22px;
    white-space: nowrap;
    width: 199px;
  }
  
  .chat-message .name {
    align-items: center;
    display: flex;
    gap: 10px;
    left: 27px;
    position: absolute;
    top: 20px;
    width: fit-content;
  }
  
  .chat-message .ellipse {
    background-color: var(--primary-1);
    border-radius: 4px;
    height: 8px;
    min-width: 8px;
    position: relative;
  }
  
  .chat-message .paul-gramard {
    color: var(--neutralblack);
    font-family: var(--button-1-font-family);
    font-size: var(--button-1-font-size);
    font-style: var(--button-1-font-style);
    font-weight: var(--button-1-font-weight);
    letter-spacing: var(--button-1-letter-spacing);
    line-height: var(--button-1-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  