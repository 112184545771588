.logo.default {
    margin-bottom: 41px;
}

.reinitialise{
    text-align: center;
    color: gray;
    margin-bottom: 50px;
}
.forgotten #email{
    width: 400px;
    margin: auto;
}
.forgotten .btn-primary {
    width: 350px;
    margin-left: 40px;
}
.forgotten .success{
    color: green;
    font-size: 16px;
    text-align: center;
}
.forgotten .error{
    color: red;
    font-size: 16px;
    text-align: center;  
}

.forgotten .loading{
    text-align: center;
    margin: auto;
}

@media (max-width: 756px) {
    .forgotten .btn-primary.send-link {
        width: 266px;
        margin-left: 15px;
    }
    .logo.default.forgot-password {
        margin-left: 40px;
    }
}