.header-goal{
    padding: 18px;
    border-bottom: 1px solid #80808014;
}

.header-goal img{
    position: absolute;
}

.header-goal h5{
    text-align: center;
}

.body h5{
    margin-bottom: 15px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
}

.body .detail{
    color: var(--neutral-dark-grey, #AEAEAE);
    text-align: center;
    font-family: Inter;
    width: 341px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 6px;
    margin: auto;
    margin-bottom: 20px
}

.list-goal ul li {
    border: 1px solid #80808029;
    padding: 7px;
    width: 314px;
    border-radius: 8px;
    font-family: Inter;
    font-size: 15px;
    margin: auto;
    margin-bottom: 15px;
}

.list-goal ul li img {
    margin-right: 15px;
}

.goal-confirm{
    width: 300px;
    margin-top: 31px;
}

.goal-button{
    text-align: center;;
}

.later{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 50px;
}

.list-goal ul {
    padding-left: 1rem !important;
}

#goal{
    height: 750px;
}

.list-goal ul li.active{
    color: #4282ff;
    border: 1px solid #4282ff;

}

#goal .body{
margin: auto;
}

#goal .more{
    font-size: 18px;
    text-align: center;
    margin-top: 50px;
}

#goal .details-goal{
    margin-top: 50px;
    border: 1px solid rgba(128, 128, 128, 0.34);
    border-radius: 7px;
    width: 310px !important;
    height: 144px;
    padding: 17px;
}

.confirm-goal h6{
    text-align: center;
    margin-top: 25px;
}

.goal-confirmed{
    text-align: center;
    border: 1px solid #80808029;
    padding: 26px;
    color: #0d6efd;
    border-radius: 8px;
    width : 350px;
    margin: auto;
    margin-top: 30px;
}

.confirm-goal .detail{
    text-align: center;
    margin-top: 27px;
    color: gray;
    font-size: 16px;
    font-weight: 400;
}

.confirm-goal .heart-circle{
    text-align: center;
    margin-bottom: 15px;
    margin-top: 25px;
    padding-top: 21px;
}
.details-goal-p{
    text-align: center;
}

.list-goal ul {
    list-style: none;
}
.inputOther{
    text-align: center;
}
.inputOther input{
    width: 314px;
    padding: 15px;
    border: 1px solid #4282ff;
    border-radius: 5px;
}

.detail-other{
    font-size: 18px;
    font-weight: 500;
    color: #0d6efd;
    text-align: center;
    margin-top: 15px;
}

.checkbox-goal{
    width: 50px;
    height: 20px;  
}

.list-goal li{
    display: flex;
}

#goal .later{
    cursor: pointer;
}

@media( min-width : 765px){
    .body .detail, .list-goal ul li{
        margin: auto
    }
    .list-goal ul li{
        margin-bottom: 20px;
    }
    .goal-confirmed{
        width : 416px;
    }
    .confirm-goal .heart-circle{
        padding-top: 21px;
    }
    .goal-confirm{
        width: 300px;
        margin-top: 11px;
    }
    
}