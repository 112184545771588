.centre {
  background-color: var(--neutralgrey);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 25px;
  position: relative;
  width: fit-content;
}

.centre .frame-3 {
  height: 64.49px;
  min-width: 1199.84px;
  position: relative;
}

.centre .button-action-instance {
  left: 1041px !important;
  position: absolute !important;
  top: 0 !important;
}

.centre .rectangle {
  background-color: var(--neutralmedium-grey);
  border-radius: 4px;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 64px;
}

.centre .group {
  height: 62px;
  left: 95px;
  position: absolute;
  top: 1px;
  width: 418px;
}

.centre .nom-du-centre {
  color: #000000;
  font-family: var(--heading-3-desktop-font-family);
  font-size: var(--heading-3-desktop-font-size);
  font-style: var(--heading-3-desktop-font-style);
  font-weight: var(--heading-3-desktop-font-weight);
  left: 0;
  letter-spacing: var(--heading-3-desktop-letter-spacing);
  line-height: var(--heading-3-desktop-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.centre .frame-4 {
  align-items: flex-start;
  display: flex;
  gap: 21px;
  left: 1px;
  position: absolute;
  top: 38px;
  width: fit-content;
}

.centre .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.centre .line {
  height: 24.43px;
  min-width: 1px;
  object-fit: cover;
  position: relative;
}

.centre .frame-wrapper {
  height: 32px;
  min-width: 1197px;
  overflow: hidden;
  position: relative;
}

.centre .frame-5 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 96px;
  position: relative;
  width: fit-content;
}

.centre.default {
  align-items: center;
  justify-content: center;
}

.centre.variant-2 {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--primary-1);
}

.centre.variant-2 .nom-du-centre {
  height: 33px;
}

.title-center{
  margin-top: 96px;
  margin-bottom: 15px;
  font-size: 34px;
}

.proximity{
  font-weight: 500;
  color: gray;
}

.container.centre{
  background: #80808014;
  width: 85%;
  padding: 18px;
  border-radius: 13px;
  margin-left: 0px;
  cursor: pointer;
  height: auto;
}

.container.centre .col-md-4{
  text-align: right;
}

.activity-name .btn-light{
  background: white;
  color: #4282ff;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 200px;
}

.centre.active .row .col-md-4{
  display: flex;
  justify-content: flex-end;
  height: auto; 
}

.centre.active .row .col-md-4 .button-centre{
  margin: auto;
}

.centre.false .row .col-md-4{
  display: flex;
  justify-content: flex-end;
  height: auto; 
}

.centre .btn-outline-primary.first{
  margin: auto !important;
  margin-right: 15px !important;
}




@media (max-width: 1536px){
  .proximity {
    margin-left: 32px;
}
.title-center {
  margin-left: 32px;
}
.container.centre {
  margin-left: 32px;
}
}

@media (min-width: 1537px) {
  .donnes-prescription .col-md-1{
    width: 4%;
  }
  .donnes-centre .col-md-1{
    width: 4%;
  }
}