.reset .logo.default {
    margin-bottom: 41px;
}

.reset .reinitialise{
    text-align: center;
    color: gray;
    margin-bottom: 50px;
}
.reset .btn-primary {
    width: 350px;
    margin-left: 40px;
}
.reset .success{
    color: green;
    font-size: 16px;
    text-align: center;
}
.reset .error{
    color: red;
    font-size: 16px;
    text-align: center;  
}

.reset .loading{
    text-align: center;
    margin: auto;
}

.reset .password-generator {
    width: 340px !important;
    position: relative!important;
    margin: auto;
    top: 0px !important;
    left: 0px !important;
}

@media (max-width: 756px){
    .reset .password-generator {
        width: 286px !important;
    }
    .reset .btn-primary {
        width: 283px;
        margin-left: 6px;
    }
    .logo.resetPasswordLogo {
        margin-left: 48px;
    }
}