.donnes-patient {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
}

.donnes-patient .div-2 {
  background-color: #ffffff;
  border: 1px none;
  height: auto;
  position: relative;
  width: 1920px;
}

.donnes-patient .steps-instance {
  left: 120px !important;
  position: absolute !important;
  top: 104px !important;
  width: 356px !important;
}

.donnes-patient .design-component-instance-node {
  overflow: hidden !important;
}

.donnes-patient .steps-2 {
  margin-right: -49px !important;
}

.donnes-patient .steps-3 {
  background-image: url(../../../static/img/icon-essential-tick-circle.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-patient .steps-4 {
  left: 120px !important;
  position: absolute !important;
  top: 207px !important;
  width: 356px !important;
}

.donnes-patient .steps-5 {
  left: 120px !important;
  position: absolute !important;
  top: 310px !important;
  width: 356px !important;
}

.donnes-patient .h-1 {
  color: var(--neutralblack);
  font-family: var(--heading-1-desktop-font-family);
  font-size: var(--heading-1-desktop-font-size);
  font-style: var(--heading-1-desktop-font-style);
  font-weight: var(--heading-1-desktop-font-weight);
  left: 545px;
  letter-spacing: var(--heading-1-desktop-letter-spacing);
  line-height: var(--heading-1-desktop-line-height);
  position: absolute;
  top: 106px;
  white-space: nowrap;
}

.donnes-patient .text-wrapper-2 {
  color: var(--primary-2, #838383);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  left: 545px;
  position: absolute;
  top: 420px;
  white-space: nowrap;
}

.donnes-patient .text-wrapper-3 {
  color: var(--primary-2, #838383);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  left: 545px;
  position: absolute;
  top: 222px;
  white-space: nowrap;
}

.donnes-patient .text-field-basic-instance {
  left: 545px !important;
  position: absolute !important;
  top: 745px !important;
  width: 547px !important;
}

.donnes-patient .text-field-basic-2 {
  background-image: url(../../../static/img/icon-arrow-arrow-down-6.svg) !important;
  left: 506px !important;
  position: absolute !important;
  top: 13px !important;
}

.donnes-patient .text-field-basic-3 {
  left: 545px !important;
  position: absolute !important;
  top: 266px !important;
  width: 547px !important;
}

.donnes-patient .text-field-basic-4 {
  left: 545px !important;
  position: absolute !important;
  top: 810px !important;
  width: 122px !important;
}

.donnes-patient .text-field-basic-5 {
  left: 687px !important;
  position: absolute !important;
  top: 810px !important;
  width: 405px !important;
}

.donnes-patient .text-field-basic-6 {
  left: 545px !important;
  position: absolute !important;
  top: 476px !important;
  width: 545px !important;
}

.donnes-patient .text-field-basic-7 {
  left: 545px !important;
  position: absolute !important;
  top: 612px !important;
  width: 547px !important;
}

.donnes-patient .text-field-basic-8 {
  left: 545px !important;
  position: absolute !important;
  top: 544px !important;
  width: 547px !important;
}

.donnes-patient .line-4 {
  height: 1px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 407px;
  width: auto;
}

.donnes-patient .bottom-buttons-instance {
  left: 545px !important;
  position: absolute !important;
  top: 833px !important;
}

.donnes-patient .bottom-buttons-2 {
  left: 1035px !important;
  position: absolute !important;
  top: 21px !important;
}

.existant-patient{
  width: 545px !important;
    position: absolute !important;
    top: 260px !important;
    left: 545px !important;
    height: 52px;
}
.form-control-lg{
  font-size: 15px !important;
}

#code-postal{
  width: 120px !important;
}

.patient-exist-button{
  position: absolute;
    top: 333px;
    left: 545px;
}

.error-message-insert{
  color: red;
    font-weight: bold;
}

.title-patient{
  position: absolute;
  top: 106px;
  left: 545px;
  color: var(--neutral-black, #2C2C2C);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

.followed{
  background: white;
  border: 1px solid green;
  width: 500px;
  color: green;
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.notFollowed{
  background: white;
  border: 1px solid red;
  width: 500px;
  color: red;
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.donnes-patient .bottom-buttons-instance .button-action-instance{
  margin-bottom: 50px;
}

.text-field-basic-9.phone{
  width: 547px !important;
    position: absolute !important;
    top: 679px !important;
    left: 545px !important;
}

/*sticky left side */

.steps.scrolled {
  position: fixed !important;
}

.donnes-patient .steps-instance.scrolled {
  top: 38px !important;
}

.donnes-patient .steps-4.scrolled {
  top: 149px !important;
}
.donnes-patient .steps-5.scrolled {
  top: 261px !important;
}
.text-field-basic.phone{
overflow: initial !important;
}

.searchaddress-patient{
  width: 341px !important;
    position: absolute !important;
    top: 745px !important;
    left: 545px !important;
    height: 52px;
}
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@media (max-width: 1536px){
  .donnes-patient .div-2 {
    width: 1495px;
  }
}

@media (max-width: 767px){
  .donnes-patient .steps-instance {
    position: relative !important;
    margin-bottom: 17px;
    top: 10px !important;
    left: 41px !important;
}
.donnes-patient .steps-4 {
  position: relative !important;
  margin-bottom: 17px;
  top: 10px !important;
    left: 41px !important;
}
.donnes-patient .steps-5 {
  position: relative!important;
  margin-bottom: 17px;
  top: 10px !important;
    left: 41px !important;
}
.title-patient {
  font-size: 26px;
  position: relative;
  top: 0px;
  left: 0px;
  margin-top: 40px;
  text-align: center;
}
.donnes-patient .text-wrapper-2 {
  position: relative;
  top: 0px;
  left: 0px;
  text-align: center;
  margin-top: 19px;
}
.donnes-patient .text-wrapper-3 {
  top: 411px;
  left: 57px;
}
.existant-patient {
  width: 350px !important;
  top: 460px !important;
  left: 37px !important;
}
.donnes-patient .text-wrapper-2 {
  top: 180px;
  left: -66px;
}
.donnes-patient .text-field-basic-8 {
  top: 708px !important;
  left: 43px !important;
  width: auto !important;
}
.donnes-patient .text-field-basic-7 {
  top: 788px !important;
  left: 44px !important;
  width: auto !important;
}
.donnes-patient .text-field-basic-instance {
  top: 868px !important;
  left: 45px !important;
  width: auto !important;
}
.donnes-patient .text-field-basic-4 {
  top: 947px !important;
  left: 45px !important;
  width: auto !important;
}
.donnes-patient .text-field-basic-5 {
  top: 1029px !important;
  left: 46px !important;
  width: auto !important;
}
.donnes-patient .text-field-basic-6 {
  top: 630px !important;
  left: 45px !important;
  width: auto !important;
}
.donnes-patient .bottom-buttons-instance {
  top: 1055px !important;
  left: 45px !important;
  width: auto !important;
}
.patient-exist-button {
  position: absolute;
  top: 89px;
  left: 37px;
}
.donnes-patient .div-2 {
  width: auto;
  height: auto;
}
.logo.default {
  margin: auto;
  margin-bottom: 64px !important;
}
}