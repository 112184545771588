@font-face {
    font-family: 'biographyregular';
    src: local('biographyregular'),
         url('/static/fonts/biography/biography-webfont.woff2') format('woff2'),
         url('/static/fonts/biography/biography-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
.signature-canvas{
    border: 1px solid rgb(128 128 128 / 26%);
    margin-top: 42px;
    border-radius: 10px;
    position: absolute;

}

#myCanvas{
    border: 1px solid rgb(128 128 128 / 26%) !important;
    margin-top: 33px;
    width: 506px;
    border-radius: 12px;
    font-family: biographyregular;
    font-size: 46px;
    padding-left: 34px;
}

.note-signature{
    width: 530px;
    margin-top: 30px;
    font-family: Inter;
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.clear-signature{
    color: #61a1fe;
    cursor: pointer;
    position: absolute;
    margin-top: 52px;
    width: 483px;
    text-align: right;
    z-index: 100;
}

.sign{
    color: #61a1fe;
    cursor: pointer;
    position: absolute;
    margin-top: 52px;
    width: 410px;
    text-align: right;
    z-index: 100;
}

.steps.scrolled {
    position: fixed !important;
}



@media (max-width: 1536px){
    .note-signature, .donnes-prescription #editor{
        margin-left: 32px;
    }
    #myCanvas {
        margin-left: 32px;
    }
    .check {
        margin-left: 32px;
    }

}

@media (min-width: 950px) and (max-width: 1400px){
    .col-md-8.finalisation-inscription-text {
        margin-left: 489px !important;
    }
  }