.title{
    margin-top: 96px;
    margin-bottom: 50px;
    font-size: 34px;
}

#typeNumber{
    width: 70px;
    font-size: 20px;
    font-weight: bold;
}

.form-outline{
    display: flex;
}

.form-outline label{
    margin-left: 20px;
    margin-top: 17px;
}

.subtitle{
    margin-top: 50px;
    margin-bottom: 20px;
}

span{
    font-size: 17px;
    font-weight: 600;
    color: grey;
    margin-bottom: 37px !important;
}
.exercise-type, .intensity{
    display: flex;
    margin-bottom: 30px;
    margin-left: 5px;
    margin-top: 20px;
}

.exercise-type .first, .exercise-type .second, .intensity button{
    width: 120px;
    margin-right: 15px;
}

.exercise-type .third{
    width: 220px;
}

textarea{
    width: 481px !important;
    height: 124px;
    margin-bottom: 50px;
}

.series{
    margin-bottom: 50px;
}

.form-switch{
    margin-left: 15px;
    margin-right: 10px;
}

.form-switch input{
    height: 20px;
}

.precaution{
    display: flex;
}

#typeText{
    width: 500px;
    margin-bottom: 20px;
}

.activity, .week{
    margin-top: 20px;
    margin-bottom: 30px;
}

.precaution-input {
    display: none;
}
.precaution-input.active{
    display: block;
}

.exercise-details.row{
    margin-bottom: 10px;
}

.check-exercise-details{
    margin-bottom: 40px;
}

.check-exercise-details strong{
    margin-left: 10px;
}

@media (max-width: 1536px){
    .formulaire-prescription{
        margin-left: 32px;
    }
}