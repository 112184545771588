.inscription-mobile.eapa {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .inscription-mobile.eapa .div-3 {
    background-color: #ffffff;
    border: 0px none;
    height: 1000px;
    overflow: hidden;
    position: relative;
    width: 393px;
  }
  
  .inscription-mobile.eapa .p {
    color: #000000;
    font-family: var(--heading-1-mobile-font-family);
    font-size: var(--heading-1-mobile-font-size);
    font-style: var(--heading-1-mobile-font-style);
    font-weight: var(--heading-1-mobile-font-weight);
    left: 57px;
    letter-spacing: var(--heading-1-mobile-letter-spacing);
    line-height: var(--heading-1-mobile-line-height);
    position: absolute;
    text-align: center;
    top: 94px;
    width: 263px;
  }
  
  .inscription-mobile.eapa .text-wrapper-2 {
    color: var(--neutralblack);
    font-family: var(--body-1-bold-font-family);
    font-size: var(--body-1-bold-font-size);
    font-style: var(--body-1-bold-font-style);
    font-weight: var(--body-1-bold-font-weight);
    left: 28px;
    letter-spacing: var(--body-1-bold-letter-spacing);
    line-height: var(--body-1-bold-line-height);
    position: absolute;
    top: 186px;
    white-space: nowrap;
    width: 263px;
  }
  
  .inscription-mobile.eapa .text-wrapper-3 {
    color: var(--neutralblack);
    font-family: var(--body-1-bold-font-family);
    font-size: var(--body-1-bold-font-size);
    font-style: var(--body-1-bold-font-style);
    font-weight: var(--body-1-bold-font-weight);
    left: 28px;
    letter-spacing: var(--body-1-bold-letter-spacing);
    line-height: var(--body-1-bold-line-height);
    position: absolute;
    top: 472px;
    white-space: nowrap;
    width: 263px;
  }
  
  .inscription-mobile.eapa .text-field-basic-instance {
    left: 17px !important;
    position: absolute !important;
    top: 236px !important;
    width: 340px !important;
  }
  
  .inscription-mobile.eapa .design-component-instance-node {
    left: 17px !important;
    position: absolute !important;
    top: 304px !important;
    width: 340px !important;
  }
  
  .inscription-mobile.eapa .text-field-basic-2 {
    left: 17px !important;
    position: absolute !important;
    top: 370px !important;
    width: 340px !important;
  }
  
  .inscription-mobile.eapa .text-field-basic-3 {
    left: 17px !important;
    position: absolute !important;
    top: 521px !important;
    width: 340px !important;
  }
  
  .inscription-mobile.eapa .text-field-basic-4 {
    left: 17px !important;
    position: absolute !important;
    top: 584px !important;
    width: 340px !important;
  }
  
  .inscription-mobile.eapa .en-cr-ant-votre {
    color: transparent;
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 52px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 771px;
    width: 289px;
  }
  
  .inscription-mobile.eapa .span {
    color: #aeaeae;
    font-family: var(--CP-light-font-family);
    font-size: var(--CP-light-font-size);
    font-style: var(--CP-light-font-style);
    font-weight: var(--CP-light-font-weight);
    letter-spacing: var(--CP-light-letter-spacing);
    line-height: var(--CP-light-line-height);
  }
  
  .inscription-mobile.eapa .text-wrapper-4 {
    color: #4282ff;
    font-family: var(--CP-bold-font-family);
    font-size: var(--CP-bold-font-size);
    font-style: var(--CP-bold-font-style);
    font-weight: var(--CP-bold-font-weight);
    letter-spacing: var(--CP-bold-letter-spacing);
    line-height: var(--CP-bold-line-height);
  }
  
  .inscription-mobile.eapa .text-wrapper-5 {
    color: #4282ff;
    font-family: var(--CP-light-font-family);
    font-size: var(--CP-light-font-size);
    font-style: var(--CP-light-font-style);
    font-weight: var(--CP-light-font-weight);
    letter-spacing: var(--CP-light-letter-spacing);
    line-height: var(--CP-light-line-height);
  }
  
  .inscription-mobile.eapa .button-action-instance {
    left: 17px !important;
    position: absolute !important;
    top: 652px !important;
    width: 341px !important;
  }
  
  .inscription-mobile.eapa .keyboard {
    -webkit-backdrop-filter: blur(108.73px) brightness(100%);
    backdrop-filter: blur(108.73px) brightness(100%);
    background-color: #d1d5db;
    height: 291px;
    left: 9px;
    position: absolute;
    top: 859px;
    width: 375px;
  }
  
  .inscription-mobile.eapa .overlap {
    height: 283px;
    left: 1px;
    position: relative;
    top: 8px;
  }
  
  .inscription-mobile.eapa .home-indicator-instance {
    left: 0 !important;
    position: absolute !important;
    top: 249px !important;
  }
  
  .inscription-mobile.eapa .keys {
    height: 259px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 369px;
  }
  
  .inscription-mobile.eapa .dictation {
    height: 25px;
    left: -3044px;
    position: absolute;
    top: -2531px;
    width: 15px;
  }
  
  .inscription-mobile.eapa .emoji {
    height: 27px;
    left: -3349px;
    position: absolute;
    top: -2532px;
    width: 27px;
  }
  
  .inscription-mobile.eapa .overlap-group {
    height: 42px;
    left: -3374px;
    position: absolute;
    top: -2772px;
    width: 182px;
  }
  
  .inscription-mobile.eapa .return {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 88px;
  }
  
  .inscription-mobile.eapa .label-3 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 88px;
  }
  
  .inscription-mobile.eapa .space {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 182px;
  }
  
  .inscription-mobile.eapa .label-4 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 182px;
  }
  
  .inscription-mobile.eapa .element {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 87px;
  }
  
  .inscription-mobile.eapa .label-5 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 87px;
  }
  
  .inscription-mobile.eapa .delete {
    height: 42px;
    left: 327px;
    position: absolute;
    top: 108px;
    width: 42px;
  }
  
  .inscription-mobile.eapa .key-light {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: -3701px;
    position: absolute;
    top: -2880px;
    width: 42px;
  }
  
  .inscription-mobile.eapa .label-6 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 42px;
  }
  
  .inscription-mobile.eapa .delete-button {
    height: 17px;
    left: -3361px;
    position: absolute;
    top: -2751px;
    width: 23px;
  }
  
  .inscription-mobile.eapa .shift {
    height: 42px;
    left: 0;
    position: absolute;
    top: 108px;
    width: 42px;
  }
  
  .inscription-mobile.eapa .overlap-group-wrapper {
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 42px;
  }
  
  .inscription-mobile.eapa .symbol-wrapper {
    background-color: #fcfcfe;
    border-radius: 4.6px;
    box-shadow: 0px 1px 0px #898a8d;
    height: 44px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 44px;
  }
  
  .inscription-mobile.eapa .symbol {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 21px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .shift-2 {
    height: 16px;
    left: -3359px;
    position: absolute;
    top: -2752px;
    width: 19px;
  }
  
  .inscription-mobile.eapa .m {
    height: 42px;
    left: 282px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .div-wrapper {
    background-color: #fcfcfe;
    border-radius: 4.6px;
    box-shadow: 0px 1px 0px #898a8d;
    height: 44px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 34px;
  }
  
  .inscription-mobile.eapa .symbol-2 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 7px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .n {
    height: 42px;
    left: 244px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .symbol-3 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .b {
    height: 42px;
    left: 206px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .symbol-4 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 10px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .v {
    height: 42px;
    left: 169px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .c {
    height: 42px;
    left: 131px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .x {
    height: 42px;
    left: 94px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .z {
    height: 42px;
    left: 56px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .l {
    height: 42px;
    left: 319px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .symbol-5 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 11px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .k {
    height: 42px;
    left: 281px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .j {
    height: 42px;
    left: 244px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .h {
    height: 42px;
    left: 206px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .g {
    height: 42px;
    left: 169px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .f {
    height: 42px;
    left: 131px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .d {
    height: 42px;
    left: 94px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .s {
    height: 42px;
    left: 56px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .a {
    height: 42px;
    left: 19px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .inscription-mobile.eapa .overlap-wrapper {
    height: 42px;
    left: 337px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .o {
    height: 42px;
    left: 300px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .symbol-6 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 8px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .i {
    height: 42px;
    left: 262px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .symbol-7 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 14px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .u {
    height: 42px;
    left: 225px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .y {
    height: 42px;
    left: 187px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .t {
    height: 42px;
    left: 150px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .r {
    height: 42px;
    left: 112px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .e {
    height: 42px;
    left: 75px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .w {
    height: 42px;
    left: 37px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .symbol-8 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 6px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .inscription-mobile.eapa .q {
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .inscription-mobile.eapa .header-mobile-instance {
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
  }
  
  .form-control-lg{
    font-size: 15px !important;
  }
  
  .sign-up{
    cursor: pointer;
  }

  .cgu{
    width: 341px !important;
    position: absolute !important;
    top: 583px !important;
    left: 17px !important;
  }

  .confidentialite{
    width: 341px !important;
    position: absolute !important;
    top: 634px !important;
    left: 17px !important;
  }

  .inscription-mobile.eapa .sign-up{
    top: 729px !important;
  }

  .inscription-mobile.eapa  .sign-up.btn-primary{
    background: #0d6efd;
    color: white;
    border: 1px solid #0d6efd;
    padding: 8px;
    border-radius: 12px;
  }

  .text-centre{
    position: absolute;
    top: 454px;
    left: 28px;
    font-family: var(--body-1-bold-font-family);
    font-size: var(--body-1-bold-font-size);
    font-style: var(--body-1-bold-font-style);
    font-weight: var(--body-1-bold-font-weight);
    letter-spacing: var(--body-1-bold-letter-spacing);
    line-height: var(--body-1-bold-line-height);
    white-space: nowrap; 
  }

  .input-centre{
    position: absolute;
    top: 501px;
    width: 334px;
    padding: 8px;
    left: 20px;
    border-radius: 9px;
    border: var(--bs-border-width) solid var(--bs-border-color);
  }

  .suggestions{
    position: absolute;
    top: 543px;
    background: #f9f7f7;
    left: 19px;
    width: 336px;
    z-index: 5;
    /* border: 59px; */
    border-radius: 0 0 10px 10px;
    padding-left: 0px !important;
}

.suggestions li {
  width: 320px;
  padding: 10px 10px;
  cursor: pointer;
}

.suggestions li:hover {
  background: #eee8e8;
}