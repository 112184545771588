.donnes-prescription {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
}

.donnes-prescription .div-2 {
  background-color: #ffffff;
  border: 1px none;
  height: auto;
  position: relative;
  width: 1920px;
}

.donnes-prescription .design-component-instance-node {
  left: 545px !important;
  position: absolute !important;
  top: 1644px !important;
}

.donnes-prescription .bottom-buttons-instance {
  left: 545px !important;
  position: absolute !important;
  top: 1799px !important;
}

.donnes-prescription .bottom-buttons-2 {
  left: 746px !important;
}

.donnes-prescription .text-field-basic-instance {
  left: 545px !important;
  position: absolute !important;
  top: 452px !important;
  width: 547px !important;
}

.donnes-prescription .text-field-basic-2 {
  background-image: url(../../../static/img/icon-arrow-arrow-down-1.svg) !important;
  left: 506px !important;
  position: absolute !important;
  top: 13px !important;
}

.donnes-prescription .text-field-basic-3 {
  left: 20px !important;
}

.donnes-prescription .text-field-basic-4 {
  left: 545px !important;
  position: absolute !important;
  top: 644px !important;
  width: 547px !important;
}

.donnes-prescription .text-field-basic-5 {
  left: 545px !important;
  position: absolute !important;
  top: 883px !important;
  width: 547px !important;
}

.donnes-prescription .text-wrapper-2 {
  color: var(--primary-1);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  height: 18px;
  left: 1100px;
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  position: absolute;
  text-align: right;
  top: 1247px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-3 {
  color: var(--primary-3);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  height: 18px;
  left: 1000px;
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  position: absolute;
  text-align: right;
  top: 1247px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-4 {
  color: var(--primary-2);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  left: 545px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 537px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-5 {
  color: var(--primary-2);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  left: 545px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 786px;
  white-space: nowrap;
}

.donnes-prescription .s-ries-facultatif {
  color: var(--primary-2);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 545px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 1032px;
  white-space: nowrap;
}

.donnes-prescription .span {
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.donnes-prescription .text-wrapper-6 {
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.donnes-prescription .text-wrapper-7 {
  color: var(--primary-2);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  left: 687px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 1032px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-8 {
  color: var(--primary-2);
  font-family: "Inter", Helvetica;
  font-size: 22px;
  font-weight: 600;
  left: 545px;
  letter-spacing: 0;
  line-height: 33px;
  position: absolute;
  top: 1187px;
  white-space: nowrap;
}

.donnes-prescription .tag-selection-instance {
  left: 545px !important;
  position: absolute !important;
  top: 577px !important;
}

.donnes-prescription .tag-selection-2 {
  left: 545px !important;
  position: absolute !important;
  top: 820px !important;
}

.donnes-prescription .tag-selection-3 {
  left: 684px !important;
  position: absolute !important;
  top: 577px !important;
}

.donnes-prescription .tag-selection-4 {
  left: 649px !important;
  position: absolute !important;
  top: 820px !important;
}

.donnes-prescription .tag-selection-5 {
  left: 807px !important;
  position: absolute !important;
  top: 577px !important;
}

.donnes-prescription .tag-selection-6 {
  left: 775px !important;
  position: absolute !important;
  top: 820px !important;
}

.donnes-prescription .p {
  color: #000000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 545px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 1240px;
  white-space: nowrap;
}

.donnes-prescription .overlap-group {
  border: 1px solid;
  border-color: #4282ff;
  border-radius: 24px;
  height: 32px;
  left: 1034px;
  position: absolute;
  top: 1240px;
  width: 58px;
}

.donnes-prescription .ellipse {
  background-color: var(--primary-1);
  border-radius: 12px;
  height: 24px;
  left: 28px;
  position: relative;
  top: 3px;
  width: 24px;
}

.donnes-prescription .text-field-basic-6 {
  left: 545px !important;
  position: absolute !important;
  top: 1287px !important;
  width: 405px !important;
}

.donnes-prescription .text-field-basic-7 {
  left: 545px !important;
  position: absolute !important;
  top: 1353px !important;
  width: 405px !important;
}

.donnes-prescription .text-field-basic-8 {
  left: 545px !important;
  position: absolute !important;
  top: 1416px !important;
  width: 405px !important;
}

.donnes-prescription .text-field-basic-9 {
  left: 545px !important;
  position: absolute !important;
  top: 1484px !important;
  width: 405px !important;
}

.donnes-prescription .text-field-basic-10 {
  left: 545px !important;
  position: absolute !important;
  top: 1549px !important;
  width: 405px !important;
}

.donnes-prescription .h-1 {
  color: var(--neutralblack);
  font-family: var(--heading-1-desktop-font-family);
  font-size: var(--heading-1-desktop-font-size);
  font-style: var(--heading-1-desktop-font-style);
  font-weight: var(--heading-1-desktop-font-weight);
  left: 545px;
  letter-spacing: var(--heading-1-desktop-letter-spacing);
  line-height: var(--heading-1-desktop-line-height);
  position: absolute;
  top: 106px;
  white-space: nowrap;
  font-size: 30px;
}

.donnes-prescription .text-wrapper-9 {
  color: var(--neutralblack);
  font-family: var(--heading-2-desktop-font-family);
  font-size: var(--heading-2-desktop-font-size);
  font-style: var(--heading-2-desktop-font-style);
  font-weight: var(--heading-2-desktop-font-weight);
  left: 545px;
  letter-spacing: var(--heading-2-desktop-letter-spacing);
  line-height: var(--heading-2-desktop-line-height);
  position: absolute;
  top: 335px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-10 {
  color: var(--neutralblack);
  font-family: var(--heading-2-desktop-font-family);
  font-size: var(--heading-2-desktop-font-size);
  font-style: var(--heading-2-desktop-font-style);
  font-weight: var(--heading-2-desktop-font-weight);
  left: 545px;
  letter-spacing: var(--heading-2-desktop-letter-spacing);
  line-height: var(--heading-2-desktop-line-height);
  position: absolute;
  top: 186px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-11 {
  color: var(--primary-2);
  font-family: var(--heading-3-desktop-font-family);
  font-size: var(--heading-3-desktop-font-size);
  font-style: var(--heading-3-desktop-font-style);
  font-weight: var(--heading-3-desktop-font-weight);
  left: 545px;
  letter-spacing: var(--heading-3-desktop-letter-spacing);
  line-height: var(--heading-3-desktop-line-height);
  position: absolute;
  top: 396px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-12 {
  color: var(--primary-2);
  font-family: var(--heading-3-desktop-font-family);
  font-size: var(--heading-3-desktop-font-size);
  font-style: var(--heading-3-desktop-font-style);
  font-weight: var(--heading-3-desktop-font-weight);
  left: 1112px;
  letter-spacing: var(--heading-3-desktop-letter-spacing);
  line-height: var(--heading-3-desktop-line-height);
  position: absolute;
  top: 396px;
  white-space: nowrap;
}

.donnes-prescription .steps-instance {
  left: 120px !important;
  position: absolute !important;
  top: 104px !important;
  width: 356px !important;
}

.donnes-prescription .steps-2 {
  overflow: hidden !important;
}

.donnes-prescription .steps-3 {
  margin-right: -49px !important;
}

.donnes-prescription .steps-4 {
  margin-right: -47.68px !important;
}

.donnes-prescription .steps-5 {
  background-image: url(../../../static/img/icon-location-location-4.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-prescription .steps-6 {
  background-image: url(../../../static/img/icon-essential-tick-circle-12.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-prescription .steps-7 {
  left: 120px !important;
  position: absolute !important;
  top: 298px !important;
  width: 356px !important;
}

.donnes-prescription .steps-8 {
  margin-right: -11px !important;
}

.donnes-prescription .steps-9 {
  left: 120px !important;
  position: absolute !important;
  top: 518px !important;
  width: 356px !important;
}

.donnes-prescription .steps-10 {
  background-image: url(../../../static/img/icon-essential-tick-circle-11.svg) !important;
  min-width: 24px !important;
  position: relative !important;
  width: unset !important;
}

.donnes-prescription .text-wrapper-13 {
  color: #000000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 1187px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 454px;
  white-space: nowrap;
}

.donnes-prescription .text-field-number-caps {
  left: 545px !important;
  position: absolute !important;
  top: 250px !important;
}

.donnes-prescription .text-field-number-instance {
  left: 1112px !important;
  position: absolute !important;
  top: 448px !important;
}

.donnes-prescription .text-wrapper-14 {
  color: #000000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 620px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 255px;
  white-space: nowrap;
}

.donnes-prescription .text-field-number-caps-instance {
  left: 545px !important;
  position: absolute !important;
  top: 1073px !important;
}

.donnes-prescription .text-field-number-2 {
  left: 687px !important;
  position: absolute !important;
  top: 1073px !important;
}

.donnes-prescription .text-wrapper-15 {
  color: #000000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 610px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 1090px;
  white-space: nowrap;
}

.donnes-prescription .text-wrapper-16 {
  color: #000000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 752px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 1090px;
  white-space: nowrap;
}

.bottom-buttons{
  text-align: right;
  margin-right: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.bottom-buttons button{
  margin: 15px;
}

.donnes-prescription .steps.done.steps-7{
  top: 325px !important;
}

.donnes-prescription .in-progress.steps-9 {
  top: 571px !important;
}

.exercise-type .btn, .row.intensity .btn{
  border-radius: 20px;
}

.donnes-prescription .title{
    color: var(--neutral-black, #2C2C2C);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

.donnes-prescription .subtitle{
  color: var(--neutral-black, #2C2C2C);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
}

.steps.scrolled {
  position: fixed !important;
}

.donnes-prescription .steps-instance.scrolled {
  top: 38px !important;
}
.donnes-prescription .steps.done.steps-7.scrolled {
  top: 265px !important;
}

.donnes-prescription .in-progress.steps-9.scrolled {
  top: 516px !important;
}

.react-confirm-alert-button-group > .not-cancelled-prescription{
  background: white !important;
  color: #0d6efd;
  border: 1px solid #0d6efd;
}

.react-confirm-alert-body{
  width: 440px;
}

.react-confirm-alert-body h1{
  text-align: center;
}



@media (max-width: 1536px){
  .donnes-prescription .div-2 {
    width: 1495px;
  }
  .donnes-prescription .form-outline.frequency,.donnes-prescription .subtitle, .donnes-prescription .title, .donnes-prescription .add-exercise{
    margin-left: 32px;
  }

  .donnes-prescription .formulaire-prescription .subtitle {
    margin-left: 0px;
  }
}

@media (min-width: 950px) and (max-width: 1400px){
  .col-md-8.define-prescription {
      margin-left: 489px !important;
  }
  .donnes-prescription .steps-7.scrolled {
    left: 60px !important;
  }
  
  .donnes-prescription .steps-9.scrolled {
    left: 60px !important;
  }
  .donnes-prescription .steps-instance.scrolled {
    left: 60px !important;
  }
}
