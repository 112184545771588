.text-field-code {
  align-items: flex-start;
  display: flex;
  gap: 7px;
  position: relative;
  width: fit-content;
}

.text-field-code .text-field-number-caps {
  min-width: 46px !important;
  width: unset !important;
}

.text-field-code .text-field-number-caps input {
  max-width: 46px !important;
  width: unset !important;
  height: 46px;
    border-radius: 8px;
    text-align: center;
    font-size: 25px;
}
