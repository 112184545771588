.header-mobile {
  background-color: var(--neutralwhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--neutralgrey);
  height: 69px;
  position: relative;
  width: 393px;
}

.header-mobile .class {
  left: 144px !important;
  top: 25px !important;
}

.header-mobile .class-2 {
  left: 24px !important;
  top: 25px !important;
}

.header-mobile .profile-picture-instance {
  left: 295px !important;
  position: absolute !important;
  top: 15px !important;
}

.header-mobile .icon-arrow-arrow-2 {
  background-image: url(../../../../static/img/icon-arrow-arrow-left.svg);
  background-size: 100% 100%;
  height: 32px;
  left: 22px;
  position: absolute;
  top: 18px;
  width: 32px;
}

.header-mobile .calendrier {
  color: var(--neutralblack);
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  height: 27px;
  left: 151px;
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  position: absolute;
  text-align: center;
  top: 21px;
  white-space: nowrap;
}

.react-confirm-alert-button-group > .logout-no{
  background: white !important;
  color: #0d6efd;
  border: 1px solid #0d6efd;
}

/*@media (min-width: 481px) and (max-width: 768px){
  .header-mobile .profile-picture-instance {
    left: 657px !important;
}
}*/