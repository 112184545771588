.login-psc{
    text-align: center;
  height: 100vh;
  margin: 0 !important;
  background: #00000012;
}

.login-psc p{
    width: 100%;
    position: absolute;
    top: 50vh;
}

