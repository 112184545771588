.text-field-basic {
  border: 0px solid;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 243px;
}

.text-field-basic input{
      height: 52px;
  }
  

.text-field-basic .label {
  left: 8px;
  position: absolute;
  white-space: nowrap;
}

.text-field-basic .text-wrapper {
  color: var(--neutralblack);
  left: 8px;
  position: absolute;
  top: 20px;
}

.text-field-basic .option {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 57px;
  white-space: nowrap;
}

.text-field-basic .option-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 96px;
  white-space: nowrap;
}

.text-field-basic .option-3 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 133px;
  white-space: nowrap;
}

.text-field-basic .line {
  height: 1px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 242px;
}

.text-field-basic .img {
  height: 1px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  top: 88px;
  width: 242px;
}

.text-field-basic .line-2 {
  height: 1px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 242px;
}

.text-field-basic .class {
  background-image: url(../../../../static/img/icon-arrow-arrow-down-5.svg) !important;
  left: 202px !important;
  position: absolute !important;
  top: 13px !important;
}

.text-field-basic .label-2 {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  height: 24px;
  left: 8px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.text-field-basic.text-area {
  height: 124px;
}

.text-field-basic.initial {
  border-color: #ececec;
}

.text-field-basic.dropdown {
  border-color: #ececec;
}

.text-field-basic.filled {
  border-color: #ececec;
}

.text-field-basic.white {
  background-color: #ffffff;
}

.text-field-basic.transparent.text-area {
  border-color: #ececec;
}

.text-field-basic.filled.dropdown {
  height: 52px;
}

.text-field-basic.active.dropdown {
  height: 170px;
}

.text-field-basic.classic.active {
  border-color: #4282ff;
  height: 54px;
}

.text-field-basic.initial.dropdown {
  height: 52px;
}

.text-field-basic.classic.filled {
  height: 55px;
}

.text-field-basic.classic.initial {
  height: 52px;
}

.text-field-basic.active.white.text-area {
  border-color: #4282ff;
}

.text-field-basic.active .label {
  color: var(--primary-1);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
}

.text-field-basic.initial .label {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  height: 24px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  top: 12px;
}

.text-field-basic.filled .label {
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
}

.text-field-basic.active.dropdown .label {
  height: 18px;
  top: 2px;
}

.text-field-basic.filled.text-area .label {
  color: var(--primary-1);
  height: 11px;
  top: 6px;
  width: 105px;
}

.text-field-basic.classic.active .label {
  height: 18px;
  top: 2px;
}

.text-field-basic.active.text-area .label {
  height: 11px;
  top: 6px;
  width: 105px;
}

.text-field-basic.classic.filled .label {
  color: var(--neutraldark-grey);
  height: 18px;
  top: 2px;
}

.text-field-basic.filled.dropdown .label {
  color: var(--neutraldark-grey);
  height: 18px;
  top: 2px;
}

.text-field-basic.text-area .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 225px;
}

.text-field-basic.transparent .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.text-field-basic.dropdown .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.text-field-basic.classic .text-wrapper {
  height: 24px;
  white-space: nowrap;
}

.text-field-basic.classic.white .text-wrapper {
  font-family: var(--label-initial-font-family);
  font-size: var(--label-initial-font-size);
  font-style: var(--label-initial-font-style);
  font-weight: var(--label-initial-font-weight);
  letter-spacing: var(--label-initial-letter-spacing);
  line-height: var(--label-initial-line-height);
}

.text-field-basic.active .label-2 {
  color: var(--neutraldark-grey);
}

.text-field-basic.initial .label-2 {
  color: var(--neutraldark-grey);
}

.text-field-basic.filled .label-2 {
  color: var(--neutralblack);
}
