.tabs-tab-item {
  height: 52px;
  position: relative;
  width: 169px;
}

.tabs-tab-item .line {
  height: 2px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 169px;
}

.tabs-tab-item .frame {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  left: 38px;
  position: absolute;
  top: 13px;
  width: fit-content;
}

.tabs-tab-item .observance {
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.tabs-tab-item .img {
  height: 0;
  left: -13841px;
  object-fit: cover;
  position: absolute;
  top: -5145px;
  width: 169px;
}

.tabs-tab-item.inactive {
  overflow: hidden;
}

.tabs-tab-item.inactive .observance {
  color: var(--neutralblack);
}

.tabs-tab-item.active .observance {
  color: var(--primary-1);
}
@media (min-width: 769px){
  .tabs-tab-item .frame {
    position: relative;
    margin: auto;
}
}