.steps {
  align-items: flex-start;
  background-color: var(--neutralgrey);
  border-radius: 8px;
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding: 17px 24px;
  position: relative;
  width: 405px;
}

.steps .frame {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.steps .COORDONN-ES-PATIENT {
  font-family: var(--body-2-bold-font-family);
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.steps .renseignez-les {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: relative;
  white-space: nowrap;
  width: 325px;
}

.steps .marie-christine {
  color: var(--neutralblack);
  position: relative;
  white-space: nowrap;
  width: 325px;
}

.steps .mariechristine {
  position: relative;
}

.steps .icon-location {
  background-image: url(../../../../static/img/icon-location-location.svg);
  background-size: 100% 100%;
  height: 24px;
  min-width: 24px;
  position: relative;
}

.steps .element-rue-henry-litollf {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  margin-top: -1px;
  position: relative;
  width: 291.68px;
}

.steps .div {
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 6px 0px;
  position: relative;
  width: fit-content;
}

.steps .element-semaines {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: 325px;
}

.steps .frame-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.steps .nom-de-l-activit {
  color: var(--neutralblack);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 291.68px;
}

.steps .element-s {
  color: var(--neutralblack);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.steps.in-progress {
  border-color: var(--primary-1);
  border-left-style: solid;
  border-left-width: 1px;
}

.steps .patient {
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.steps .centre-APA {
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.steps .prescription {
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
}

.steps .type-patient {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  white-space: nowrap;
  width: 325px;
}

.steps .type-centre-APA {
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 6px 0px;
  width: fit-content;
}

.steps .type-prescription {
  width: 325px;
}

.steps.done .COORDONN-ES-PATIENT {
  color: var(--neutralblack);
}

.steps.in-progress .COORDONN-ES-PATIENT {
  color: var(--primary-1);
}

.steps.to-do .COORDONN-ES-PATIENT {
  color: var(--primary-2);
}

.steps.done .renseignez-les {
  color: var(--primary-1);
}

.steps.in-progress .renseignez-les {
  color: var(--primary-1);
}

.steps.to-do .renseignez-les {
  color: var(--primary-2);
}

.steps.done{
  cursor: pointer;
}

.steps .div .btn{
  display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}
