.inscription-mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.inscription-mobile .div-3 {
  background-color: #ffffff;
  border: 0px none;
  height: 1100px;
  overflow: hidden;
  position: relative;
  width: 393px;
}

.inscription-mobile .p {
  color: #000000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  left: 57px;
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  position: absolute;
  text-align: center;
  top: 94px;
  width: 263px;
}

.inscription-mobile .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  left: 28px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 186px;
  white-space: nowrap;
  width: 263px;
}

.inscription-mobile .text-wrapper-3 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  left: 28px;
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  position: absolute;
  top: 472px;
  white-space: nowrap;
  width: 263px;
}

.inscription-mobile .text-field-basic-instance {
  left: 17px !important;
  position: absolute !important;
  top: 236px !important;
  width: 340px !important;
}

.inscription-mobile .design-component-instance-node {
  left: 17px !important;
  position: absolute !important;
  top: 304px !important;
  width: 340px !important;
}

.inscription-mobile .text-field-basic-2 {
  left: 17px !important;
  position: absolute !important;
  top: 370px !important;
  width: 340px !important;
}

.inscription-mobile .text-field-basic-3 {
  left: 17px !important;
  position: absolute !important;
  top: 521px !important;
  width: 340px !important;
}

.inscription-mobile .text-field-basic-4 {
  left: 17px !important;
  position: absolute !important;
  top: 584px !important;
  width: 340px !important;
}

.inscription-mobile .en-cr-ant-votre {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 771px;
  width: 289px;
}

.inscription-mobile .span {
  color: #aeaeae;
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.inscription-mobile .text-wrapper-4 {
  color: #4282ff;
  font-family: var(--CP-bold-font-family);
  font-size: var(--CP-bold-font-size);
  font-style: var(--CP-bold-font-style);
  font-weight: var(--CP-bold-font-weight);
  letter-spacing: var(--CP-bold-letter-spacing);
  line-height: var(--CP-bold-line-height);
}

.inscription-mobile .text-wrapper-5 {
  color: #4282ff;
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.inscription-mobile .button-action-instance {
  left: 17px !important;
  position: absolute !important;
  top: 1030px!important;
  width: 341px !important;
  z-index: 1;
}

.inscription-mobile .keyboard {
  -webkit-backdrop-filter: blur(108.73px) brightness(100%);
  backdrop-filter: blur(108.73px) brightness(100%);
  background-color: #fff;
  height: 291px;
  left: 9px;
  position: absolute;
  top: 1000px;
  width: 375px;
}

.inscription-mobile .overlap {
  height: 283px;
  left: 1px;
  position: relative;
  top: 8px;
}

.inscription-mobile .home-indicator-instance {
  left: 0 !important;
  position: absolute !important;
  top: 249px !important;
}

.inscription-mobile .keys {
  height: 259px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 369px;
}

.inscription-mobile .dictation {
  height: 25px;
  left: -3044px;
  position: absolute;
  top: -2531px;
  width: 15px;
}

.inscription-mobile .emoji {
  height: 27px;
  left: -3349px;
  position: absolute;
  top: -2532px;
  width: 27px;
}

.inscription-mobile .overlap-group {
  height: 42px;
  left: -3374px;
  position: absolute;
  top: -2772px;
  width: 182px;
}

.inscription-mobile .return {
  background-image: url(../../../static/img/shift.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 88px;
}

.inscription-mobile .label-3 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 88px;
}

.inscription-mobile .space {
  background-image: url(../../../static/img/shift.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 182px;
}

.inscription-mobile .label-4 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 182px;
}

.inscription-mobile .element {
  background-image: url(../../../static/img/shift.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 87px;
}

.inscription-mobile .label-5 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 87px;
}

.inscription-mobile .delete {
  height: 42px;
  left: 327px;
  position: absolute;
  top: 108px;
  width: 42px;
}

.inscription-mobile .key-light {
  background-image: url(../../../static/img/shift.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 42px;
  left: -3701px;
  position: absolute;
  top: -2880px;
  width: 42px;
}

.inscription-mobile .label-6 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 42px;
}

.inscription-mobile .delete-button {
  height: 17px;
  left: -3361px;
  position: absolute;
  top: -2751px;
  width: 23px;
}

.inscription-mobile .shift {
  height: 42px;
  left: 0;
  position: absolute;
  top: 108px;
  width: 42px;
}

.inscription-mobile .overlap-group-wrapper {
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 42px;
}

.inscription-mobile .symbol-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  box-shadow: 0px 1px 0px #898a8d;
  height: 44px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 44px;
}

.inscription-mobile .symbol {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 21px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .shift-2 {
  height: 16px;
  left: -3359px;
  position: absolute;
  top: -2752px;
  width: 19px;
}

.inscription-mobile .m {
  height: 42px;
  left: 282px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .div-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  box-shadow: 0px 1px 0px #898a8d;
  height: 44px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 34px;
}

.inscription-mobile .symbol-2 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 7px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .n {
  height: 42px;
  left: 244px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .symbol-3 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 9px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .b {
  height: 42px;
  left: 206px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .symbol-4 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 10px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .v {
  height: 42px;
  left: 169px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .c {
  height: 42px;
  left: 131px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .x {
  height: 42px;
  left: 94px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .z {
  height: 42px;
  left: 56px;
  position: absolute;
  top: 108px;
  width: 32px;
}

.inscription-mobile .l {
  height: 42px;
  left: 319px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .symbol-5 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 11px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .k {
  height: 42px;
  left: 281px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .j {
  height: 42px;
  left: 244px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .h {
  height: 42px;
  left: 206px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .g {
  height: 42px;
  left: 169px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .f {
  height: 42px;
  left: 131px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .d {
  height: 42px;
  left: 94px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .s {
  height: 42px;
  left: 56px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .a {
  height: 42px;
  left: 19px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.inscription-mobile .overlap-wrapper {
  height: 42px;
  left: 337px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .o {
  height: 42px;
  left: 300px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .symbol-6 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 8px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .i {
  height: 42px;
  left: 262px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .symbol-7 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 14px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .u {
  height: 42px;
  left: 225px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .y {
  height: 42px;
  left: 187px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .t {
  height: 42px;
  left: 150px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .r {
  height: 42px;
  left: 112px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .e {
  height: 42px;
  left: 75px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .w {
  height: 42px;
  left: 37px;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .symbol-8 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  left: 6px;
  letter-spacing: -0.55px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.inscription-mobile .q {
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.inscription-mobile .header-mobile-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

.form-control-lg{
  font-size: 15px !important;
}

.sign-up{
  cursor: pointer;
}

.cgu.patient {
  width: 341px!important;
  position: absolute!important;
  top: 717px!important;
  left: 17px!important;
}

.info.patient {
  width: 341px!important;
  position: absolute!important;
  top: 866px!important;
  left: 17px!important;
}

.cgu.patient a {
  color: #0d6efd;
  cursor: pointer;
}

.info.patient a {
  color: #0d6efd;
}

.inscription-mobile .logout{
  display: none;
}