.logo-auth{
    width: 200px !important;
    height: 100px !important;
    margin-left: 110px;
    position: relative;
}

.login{
    text-align: center;
}

.error{
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.login .btn{
    margin: auto;
}

.login-button{
    border: 1px solid #0d6efd !important;
    color: #0d6efd !important;
    background: white !important;
}
.login-button:hover{
    border: 1px solid var(--bs-btn-hover-bg);
    color: var(--bs-btn-hover-bg);
}

@media (max-width: 768px){
    .logo-auth {
        margin-left: 41px;
    }
    .col.forgot-password{
        font-size: 13px;
    }
    .col.justify-content-center, .text-center {
        font-size: 13px;
    }
}