.inscription-mobile.eapa .p.inscription-completer {
    width: 305px;
    top: 94px;
    left: 28px;
}

.inscription-mobile.eapa .text-wrapper-3.mot-de-passe {
    width: 263px;
    top: 221px;
    left: 45px;
}

.eapa .password-generator {
    width: 340px !important;
    position: absolute !important;
    top: 269px !important;
    left: 17px !important;
}

.inscription-mobile.eapa .sign-up.valider-mot-de-passe {
    top: 465px !important;
}

.header-password-eapa .rounded-circle.logout{
display: none;
}

.div-3.mot-de-passe-div{
    height: 550px !important;
}