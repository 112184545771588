.pagination{
    float: right;
    margin-top: 21px;
    margin-right: 113px;
}

.pagination .pagination-button{
    width: 58px;
    background: white;
    border: 1px solid #0d6efd;
    color: #0d6efd;
    border-radius: 5px;
}

.pagination .pagination-button.active{
    background: #0d6efd;
    border: 1px solid white;
    color: white;
}

@media (min-width: 1920px) {
    .pagination{
        margin-right: 300px;
    } 
}