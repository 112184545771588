.card-programmer-une {
  align-items: flex-start;
  background-color: var(--neutralwhite);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  padding: 30px 20px;
  position: relative;
  width: fit-content;
}

.card-programmer-une .programmez-vos-s {
  color: var(--primary-1);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 299px;
}

.card-programmer-une .maison-hygeia {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
}

.card-programmer-une .text-wrapper {
  color: #2b2b2b;
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.card-programmer-une .span {
  color: #aeaeae;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.card-programmer-une .button-action-instance {
  width: 299px !important;
}

@media (min-width: 769px){
  .card-programmer-une {
    width: 416px;
  }
  .card-programmer-une .programmez-vos-s {
    width: 416px;
  }
  .card-programmer-une .button-action-instance {
    width: 374px !important;
  }
}