.button-action {
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: fit-content;
}

.button-action .icon-company-logos {
  background-size: 100% 100%;
  height: 24px;
  min-width: 24px;
  position: relative;
}

.button-action .connecter-whatsapp {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button-action.google-agenda {
  border: 1px solid;
}

.button-action.outlook {
  border: 1px solid;
}

.button-action.color-transparent {
  border: 1px solid;
  border-color: var(--primary-1);
  gap: 8px;
  padding: 6px 12px;
}

.button-action.color-light {
  border: 1px solid;
  gap: 10px;
  padding: 6px 17px;
}

.button-action.whatsapp {
  border: 1px solid;
}

.button-action.secondary {
  border: 1px solid;
}

.button-action.messenger {
  border: 1px solid;
}

.button-action.color-light.whatsapp {
  border-color: var(--neutralwhite);
}

.button-action.google-agenda.color-dark {
  border-color: #d34e25;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.primary.state-1-disabled {
  background-color: #abc7ff;
}

.button-action.color-light.primary {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.outlook {
  border-color: #2783d8;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.tertiary.color-dark {
  background-color: var(--primary-1);
  gap: 8px;
  padding: 6px 12px;
}

.button-action.whatsapp.color-dark {
  border-color: #25d366;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.state-1-initial.primary {
  background-color: var(--primary-1);
}

.button-action.color-light.messenger {
  border-color: var(--neutralwhite);
}

.button-action.color-light.state-1-disabled {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.secondary {
  gap: 10px;
  padding: 12px 17px;
}

.button-action.google-agenda.color-light {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.primary {
  gap: 10px;
  padding: 12px 17px;
}

.button-action.state-1-initial.secondary {
  border-color: var(--primary-1);
}

.button-action.color-light.outlook {
  border-color: var(--neutralwhite);
}

.button-action.color-light.tertiary {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.messenger {
  border-color: #267cef;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.secondary.color-dark.state-1-disabled {
  border-color: var(--neutraldark-grey);
}

.button-action.google-agenda .icon-company-logos {
  background-image: url(../../../../static/img/icon-company-logos-google.svg);
}

.button-action.outlook .icon-company-logos {
  background-image: url(../../../../static/img/icon-emails-messages-sms.svg);
}

.button-action.tertiary .icon-company-logos {
  background-image: url(../../../../static/img/icon-company-logos-whatsapp.svg);
}

.button-action.primary .icon-company-logos {
  background-image: url(../../../../static/img/icon-company-logos-whatsapp.svg);
}

.button-action.whatsapp .icon-company-logos {
  background-image: url(../../../../static/img/icon-company-logos-whatsapp.svg);
}

.button-action.secondary .icon-company-logos {
  background-image: url(../../../../static/img/icon-company-logos-whatsapp.svg);
}

.button-action.messenger .icon-company-logos {
  background-image: url(../../../../static/img/icon-company-logos-messenger.svg);
}

.button-action.google-agenda .connecter-whatsapp {
  color: #d34e25;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.outlook .connecter-whatsapp {
  color: #2783d8;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.tertiary .connecter-whatsapp {
  font-family: var(--button-2-font-family);
  font-size: var(--button-2-font-size);
  font-style: var(--button-2-font-style);
  font-weight: var(--button-2-font-weight);
  letter-spacing: var(--button-2-letter-spacing);
  line-height: var(--button-2-line-height);
}

.button-action.primary .connecter-whatsapp {
  color: var(--neutralwhite);
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.whatsapp .connecter-whatsapp {
  color: #25d366;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.secondary .connecter-whatsapp {
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.messenger .connecter-whatsapp {
  color: #267cef;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.color-light.secondary .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.tertiary.color-dark .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.tertiary.color-transparent .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.color-transparent.secondary .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.color-light.tertiary .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.secondary.color-dark.state-1-disabled .connecter-whatsapp {
  color: var(--neutraldark-grey);
}

.button-action.state-1-initial.color-dark.secondary .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.state-1-initial{
  cursor: pointer;
}