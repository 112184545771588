.password-generator .modal-content{
    width: 90% !important;
    margin-top: 145px;
  }
  .password-generator .password-copied {
    text-align: left;
    font-size: 12px;
  }
  .password-generator .confirm-button .btn {
    width: 124px;
    margin-right: 6px;
    margin-top: 30px;
}
.password-generator #generate{
    font-size: 10px;
      color: white !important;
      margin-bottom: 42px;
  }
  
  .password-generator{
    width: 340px !important;
    position: absolute !important;
    top: 510px !important;
    left: 17px !important;
  }
