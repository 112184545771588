.information-date {
  height: 18px;
  position: relative;
  width: 340px;
}

.information-date .lun-mai {
  color: var(--neutraldark-grey);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  left: 122px;
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
}

.information-date .line-3 {
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 90px;
}

.information-date .line-4 {
  height: 1px;
  left: 250px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 90px;
}