.home-indicator {
  height: 34px;
  position: relative;
  width: 375px;
}

.home-indicator .div {
  background-color: var(--label-color-dark-primary);
  border-radius: 100px;
  height: 5px;
  left: 121px;
  position: relative;
  top: 21px;
  width: 134px;
}

.home-indicator .mode-dark {
  height: 34px;
}

.home-indicator .mode-light {
  background-color: var(--label-color-light-primary);
  border-radius: 100px;
  height: 5px;
  left: 121px;
  position: relative;
  top: 21px;
  width: 134px;
}
