.profile-picture {
  background-position: 50% 50%;
  background-size: cover;
}

.profile-picture .SL {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  height: 21px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 26px;
}

.profile-picture.small {
  height: 40px;
  width: 40px;
}

.profile-picture.medium {
  height: 60px;
  width: 60px;
}

.profile-picture.empty {
  position: relative;
}

.profile-picture.big {
  height: 100px;
  width: 100px;
}

.profile-picture.image.big {
  background-image: url(../../../../static/img/ellipse-1-1.png);
}

.profile-picture.small.empty {
  background-image: url(../../../../static/img/ellipse-1-6.png);
}

.profile-picture.small.image {
  background-image: url(../../../../static/img/ellipse-1-5.png);
}

.profile-picture.big.empty {
  background-image: url(../../../../static/img/ellipse-1-2.png);
}

.profile-picture.image.medium {
  background-image: url(../../../../static/img/ellipse-1-3.png);
}

.profile-picture.medium.empty {
  background-image: url(../../../../static/img/ellipse-1-4.png);
}

.profile-picture.medium .SL {
  left: 17px;
  top: 19px;
}

.profile-picture.big .SL {
  left: 37px;
  top: 39px;
}

.profile-picture.small .SL {
  left: 7px;
  top: 9px;
}
