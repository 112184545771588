.observance-mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.observance-mobile .div-2 {
  background-color: #ffffff;
  border: 0px none;
  height: 980px;
  position: relative;
  width: 393px;
}

.observance-mobile .tabs-tabs-instance {
  left: 0 !important;
  position: absolute !important;
  top: 69px !important;
  width: 393px !important;
}

.observance-mobile .tabs-tabs-2 {
  left: 19px !important;
  width: 356px !important;
}

.observance-mobile .tabs-tabs-3 {
  left: 52px !important;
}

.observance-mobile .tabs-tabs-4 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 197px !important;
}

.observance-mobile .inactive.tabs-tabs-4 {
  left: -39px !important;
}


.observance-mobile .tabs-tabs-5 {
  width: 197px !important;
}

.observance-mobile .tabs-tabs-6 {
  height: 1px !important;
  top: 23px !important;
  left: 38px !important;
}

.observance-mobile .tabs-tabs-7 {
  left: 190px !important;
  position: absolute !important;
  top: 0 !important;
  width: 195px !important;
}
.observance-mobile .active.tabs-tabs-7 {
  left: 211px !important;
  top: 1px !important;
}

.observance-mobile .tabs-tabs-8 {
  left: 57px !important;
}

.observance-mobile .active.tabs-tabs-7 .tabs-tabs-8 {
  left: 0px !important;
}
.observance-mobile .text-wrapper-10 {
  color: #000000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  left: 65px;
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  position: absolute;
  text-align: center;
  top: 160px;
  width: 263px;
}

.observance-mobile .text-wrapper-11 {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 26px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  text-align: center;
  top: 230px;
  width: 341px;
}

.observance-mobile .text-wrapper-5 {
  color: #000000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  left: 65px;
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  position: absolute;
  text-align: center;
  top: 160px;
  white-space: nowrap;
  width: 263px;
}

.observance-mobile .p {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 26px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  text-align: center;
  top: 200px;
  width: 341px;
}

.observance-mobile .icon-arrow-arrow-down {
  background-image: url(../../../static/img/icon-arrow-arrow-down.svg) !important;
  left: 184px !important;
  position: absolute !important;
  top: 264px !important;
}

.observance-mobile .frame-3 {
  align-items: flex-start;
  background-color: var(--neutralgrey);
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 850px;
  left: 0;
  overflow: hidden;
  padding: 20px 26px;
  position: relative;
  top: 30px;
  width: 393px;
}

.observance-mobile .information-date-instance {
  align-self: stretch !important;
  min-width: 341px !important;
  width: unset !important;
}

.observance-mobile .information-date-2 {
  left: 123px !important;
}

.observance-mobile .information-date-3 {
  left: 251px !important;
}

.observance-mobile .header-mobile-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

.observance-mobile .header-mobile-2 {
  background-image: url(../../../static/img/icon-time-calendar.svg) !important;
  height: 32px !important;
  left: 251px !important;
  position: absolute !important;
  top: 18px !important;
  width: 32px !important;
}

.observance-mobile .bubble-info-instance {
  left: 32px !important;
  position: fixed !important;
  top: 793px !important;
}

.observance-mobile .bubble-info-2 {
  border-color: unset !important;
  left: 283px !important;
  position: absolute !important;
  top: 90px !important;
}

.observance-mobile .bubble-info-3 {
  background-image: url(../../../static/img/icon-essential-emoji-sad.svg) !important;
  left: 12px !important;
  position: relative !important;
  top: 12px !important;
}

.observance-mobile .bubble-info-4 {
  border-color: unset !important;
  left: 283px !important;
  position: absolute !important;
  top: 34px !important;
}

.observance-mobile .bubble-info-5 {
  background-image: url(../../../static/img/icon-essential-share.svg) !important;
  left: 12px !important;
  position: relative !important;
  top: 12px !important;
}

.header-mobile .logout{
  position: absolute;
  left: 330px;
  top: 17px;
}

@media (min-width: 375px) and (max-width: 769px){
  .observance-mobile .bubble-info-instance {
    top: 684px !important;
    left: 67px !important;
}
}

@media (max-width: 375px){
  .observance-mobile .bubble-info-instance {
    top: 516px !important;
    left: 47px !important;
}
}

@media (min-width: 769px) and (max-width: 1565px){
  .observance-mobile .bubble-info-instance {
    top: 552px !important;
    left: 744px !important;
}
}

@media (min-width: 1565px){
  .observance-mobile .bubble-info-instance {
    top: 741px !important;
    left: 932px !important;
  }
}

 @media (min-width: 769px) {
  .observance-mobile .header-mobile-instance {
    width: 100%;
  }
  .observance-mobile .header-mobile-2 {
    left: initial !important;
    right: 130px;
}
.header-mobile .profile-picture-instance {
  left: initial !important;
  right: 70px;
}
.header-mobile .class-2 {
  left: 75px !important;
}

.observance-mobile .tabs-tabs-instance {
  width: auto !important;
  position: relative !important;
}
.observance-mobile .div-2 {
  width: 750px !important;
}
.observance-mobile .tabs-tabs-4 {
  position: relative !important;
  width: 375px !important;
}
.observance-mobile .tabs-tabs-7 {
width: 375px !important;
position: relative !important;
left: 0px !important;
}
.observance-mobile .frame-3 {
  width: 750px;
  position: relative;
  top: 30px;
}

.observance-mobile .information-date-instance {
  margin: auto;
}
.card-programmer-une {
  position: relative;
  margin: auto;
}
.card-ajout-d-une {
  position: relative;
  margin: auto;
}
.observance-mobile .text-wrapper-5 {
  left: 225px;
}
.observance-mobile .p {
  left: 184px;
}
.observance-mobile .icon-arrow-arrow-down {
  left: 339px !important;
}
.observance-mobile img.tabs-tabs-5, .observance-mobile .active.tabs-tabs-7 img.tabs-tabs-8{
  display: none;
}

.observance-mobile .active.tabs-tabs-4 .tabs-tabs-3{
  border-bottom: 1px solid #4282ff;
  width: 100%;
  padding-bottom: 13px;
}
.observance-mobile .active.tabs-tabs-7 .tabs-tabs-6{
  border-bottom: 1px solid #4282ff;
  width: 100%;
  padding-bottom: 20px;
}
.observance-mobile .active.tabs-tabs-7 {
  left: 0px !important;
}
.header-mobile .logout {
  position: absolute;
  top: 17px;
  left: initial;
  right: 15px;
}
}

.tabs-tab-item.inactive.tabs-tabs-7{
  display:none
}

/*@media (min-width: 481px) and (max-width: 768px){
  .header-mobile .logout {
    left: 700px;
  }
  .observance-mobile .header-mobile-2 {
    width: 40px !important;
    height: 40px !important;
    top: 15px !important;
    left: 600px !important;
}
}*/