.logo {
  left: 0;
  position: absolute;
  top: 0;
}

.logo.default {
  height: 18px;
  width: 86px;
}

.logo.variant-2 {
  height: 16px;
  width: 17px;
}
