.tabs-tabs {
  background-color: var(--neutralwhite);
  height: 52px;
  width: 375px;
}

.tabs-tabs .overlap-group {
  height: 52px;
  position: relative;
}

.tabs-tabs .line-2 {
  height: 1px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 340px;
}

.first-session .text-wrapper-5{
  font-size: 22px !important;
  text-wrap: balance !important;
  top: 82px !important;
}

.first-session .p{
  top: 165px !important;
}

.first-session .btn{
  position: absolute;
  top: 240px;
  left: 118px;
}

.first-session{
  height: 290px;
}

.card-survey {
  align-items: flex-start;
  background-color: var(--neutralwhite);
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  padding: 16px 21px;
  position: relative;
  width: 340px;
  margin: auto;
  margin-top: 20px;
}

.title-evaluation{
  color: var(--neutral-dark-grey, #AEAEAE);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.evaluation-todo{
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-left: 27px;
  color: #4282ff;
  font-weight: 500;
}

.question .text-wrapper-14{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    color: #4282ff;
    font-weight: 500;
    font-size: 15px;
}

.evaluation .button-evaluation {
  margin: 6px;
  margin-left: -7px;
}

.evaluation .group-5, .evaluation .group-6 {
  margin-right: 9px;
}

.frame-3.evaluation{
  height: auto !important;
}

.confirm-goal h6{
  text-align: center;
  margin-top: 25px;
}

.goal-confirmed{
  text-align: center;
  border: 1px solid #80808029;
  padding: 26px;
  margin-top: 25px;
  color: #0d6efd;
  border-radius: 8px;
}

.confirm-goal .detail{
  text-align: center;
  margin-top: 27px;
  color: gray;
  font-size: 16px;
  font-weight: 400;
}

.confirm-goal .heart-circle{
  text-align: center;
  margin-bottom: 15px;
  margin-top: 25px;
}

.frame-3.scheduled{
  position: initial !important;
  height: auto !important;
}

.qr-code{
  background: #4282ff;
    padding: 29px;
    border-radius: 13px;
    margin-top: 12px;
    width: 340px;
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative;
    margin-bottom: 20px;
}
.qr-code p.text{
  text-align: center;
  color: white;
  font-weight: 500;
}
.qr-code p.image{
  text-align: center;
}

.qr-code p.image img{
  width : 160px;
}
.first-session .text-wrapper-5{
  position: relative !important;
  top: 34px !important;
}
.first-session .p{
  position: relative !important;
  top: 35px !important;
}

.first-session .btn{
  position: relative !important;
  top: 38px !important;
}
.observance-mobile .default-screen{
background-color: var(--neutralgrey);
}
.observance-mobile .default-screen .text-wrapper-5 {
  top: 100px;
  left: 65px;
}
.observance-mobile .default-screen .p {
  top: 140px;
  left: 26px;
}
.observance-mobile .default-screen .btn.btn-primary {
position: absolute;
top: 217px;
left: 103px;
}

.frame-3.default{
position: relative !important;
top: 0px !important;
}

.evaluation .button-evaluation, .evaluation .group, .evaluation .group-6 {
  cursor: pointer;
}

.modal-content{
width: 90% !important;
margin: auto;
}

.welcome-apaora{
  background: white;
  width: 100%;
  padding-bottom: 22px;
}

.welcome-apaora .welcome{
  text-align: center;
    margin-top: 50px;
    font-weight: 500;
    font-size: 18px;
}

.welcome-apaora .welcome-subtitle{
  text-align: center;
    margin-top: 20px;
    color: #808080ab;
}

.observance-mobile .default-screen .welcome-apaora .btn.btn-primary {
  position: relative !important;
  top: 0px; 
  left: 103px;
}

.observance-mobile .welcome-apaora .icon-arrow-arrow-down {
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  margin-top: 25px;
}

.define-goal{
  margin: auto;
  text-align: center;
}

.define-goal .btn.btn-primary {
  left: initial !important;
}

.btn.yes, .btn.no{
  width: 138px;
  margin-left: 5px;
  margin-right: 5px;
}

.question-no ul{
  padding-left: 0px !important;
}

.question-no ul li{
margin-bottom: 10px !important;
}

.question-no{
  margin-top: 20px;
  transition: max-height 0.5s ease-in-out;
  max-height: 500px;
  overflow: hidden;
}

.question-no .other{
  width: 294px;
  padding: 7px;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-bottom: 24px;
}

.question-no .btn.send {
   width: 294px;
}

.question-no p {
  text-align: center;
}

.question .btn.btn-primary {
  position: relative !important;
  top: 0px !important;
    left: 0px !important;
}

.evaluation .session-missing {
  padding-left: 0px;
  font-size: 14px;
}

.evaluation .session-missing li {
  margin-bottom: 12px;
    display: flex;
    align-items: center;
}

.evaluation .session-missing li img {
  max-width: 100px;
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.evaluation  p{
  text-align: center;
}
.evaluation .send{
  width: 250px;
}


.modal-backdrop.show {
  width: 100%;
  height: 100%;
}

.bubble-info {
display: none
}

@media (max-width: 768px){
  .qr-code {
    margin-bottom: 0px;
}
.card-survey {
  margin: 15px auto auto;
}
.observance-mobile .session-list .information-date {
  margin-bottom: 25px;
  margin-top: 11px;
}

}

@media (min-width: 769px){
  .tabs-tabs .overlap-group {
    display: flex;
    position: relative;
  }
  .frame-3.evaluation .card-survey, .session-list{
    margin: auto;
  }
  .observance-mobile .default-screen .text-wrapper-5 {
    top: 100px;
    left: 216px;
}
.observance-mobile .default-screen .p {
  top: 140px;
  left: 178px;
}
.observance-mobile .default-screen .btn.btn-primary {
  position: absolute;
  top: 217px;
  left: 267px;
}
.card-survey {
  width: 416px;
}
.evaluation .button-evaluation {
  margin-right: 34px !important;
}
.evaluation .group-5, .evaluation .group-6 {
  margin-right: 33px;
}
.evaluation-todo {
  margin-left: 100px;
}
.qr-code {
  width: 416px;
}
.observance-mobile .session-list .information-date {
  height: 36px !important;
  width: 340px !important;
}

}