.dropdown.text-end{
    padding: 34px;
}

.dropdown.text-end .new-prescription, .dropdown.text-end .message{
    margin-right: 25px;
}

.search-patient{
    text-align: center;
    margin-bottom: 50px;
    background: var(--neutral-grey, #F9F9F9);
    padding: 50px;
    border-radius: 5px;
}

.search-patient h2{
    font-size: 36px;
    font-weight: 400;
    line-height: 46px;
    font-style: normal;
    color: var(--neutral-black, #2C2C2C);
}

#form12{
    width: 500px;
    margin: auto;
    margin-top: 20px;  
}

.table th{
    font-size: 14px;
    line-height: 150%;
    font-weight: 600;
    font-family: Inter;
    color: var(--neutral-dark-grey, #AEAEAE);
    background: var(--neutral-grey, #F9F9F9);
}

.badge-followed{
    color: green;
    border: 1px solid green;
    font-size: 15px;
    padding: 5px;
    border-radius: 20px;
    display: block;
    text-align: center;
    width: 100px;
}

.badge-non-followed{
    color: red;
    border: 1px solid red;
    font-size: 15px;
    padding: 5px;
    border-radius: 20px;
    display: block;
    text-align: center;
    width: 100px;
}

.logout{
    margin-left: 20px;
    cursor: pointer;
}

.btn.new-prescription{
    display: inline-flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.table.bg-white td {
    height: 70px; 
}
.align-items-center {
    align-items: center !important;
    height: 100%;
}
.container.recherche-patient{
    height: 90px;
}

.react-confirm-alert-button-group > button {
    background: #0d6efd !important;
    width: 150px !important;
    margin: auto !important;
}

.react-confirm-alert-button-group > .logout-no{
    background: white !important;
    color: #0d6efd;
    border: 1px solid #0d6efd;
}
.messageIcon{
    display:none
}
#dropdownUser1{
    border: 2px solid;
    border-radius: 100%;
    padding: 4px;
    text-decoration: none;
    font-weight: bold;
}  
.message-searchAPA{
    margin-right: 25px
} 
