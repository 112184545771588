.card-RDV {
  background-color: #ffffff;
  border-radius: 16px;
  height: 243px;
  overflow: hidden;
  position: relative;
  width: 340px;
}

.card-RDV .div {
  color: var(--primary-1);
  font-family: var(--display-2-font-family);
  font-size: var(--display-2-font-size);
  font-style: var(--display-2-font-style);
  font-weight: var(--display-2-font-weight);
  left: 27px;
  letter-spacing: var(--display-2-letter-spacing);
  line-height: var(--display-2-line-height);
  position: absolute;
  top: 54px;
  white-space: nowrap;
}

.card-RDV .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  left: 27px;
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.card-RDV .text-wrapper-3 {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  left: 195px;
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.card-RDV .text-wrapper-4 {
  color: var(--primary-1);
  font-family: var(--display-2-font-family);
  font-size: var(--display-2-font-size);
  font-style: var(--display-2-font-style);
  font-weight: var(--display-2-font-weight);
  left: 195px;
  letter-spacing: var(--display-2-letter-spacing);
  line-height: var(--display-2-line-height);
  position: absolute;
  top: 54px;
  white-space: nowrap;
}

.card-RDV .vector {
  height: 74px;
  left: 169px;
  position: absolute;
  top: 30px;
  width: 1px;
}

.card-RDV .tag-instance {
  left: 15px !important;
  position: absolute !important;
  top: 178px !important;
}

.card-RDV .design-component-instance-node {
  left: 169px !important;
  position: absolute !important;
  top: 126px !important;
}

.card-RDV .text-wrapper-2, .card-RDV .text-wrapper-3 {
  font-size: 19px;
}

.card-RDV .div, .card-RDV .text-wrapper-4 {
  font-size: 33px;
  font-weight: 500;
}

.centre-cardRdv{
  margin-top: 114px;
}

.centre-cardRdv .maison-hygeia{
  text-align: center;
}

.centre-cardRdv .maison-hygeia .text-wrapper{
  color: var(--neutral-black, #2C2C2C);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.centre-cardRdv .maison-hygeia .span{
  color: var(--neutral-dark-grey, #AEAEAE);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.col.activity-tag{
  color: var(--primary-1);
    background: rgb(128 128 128 / 8%);
    padding: 5px;
    border-radius: 8px;
    margin-left: 20px;
    align-content: center;
}

.row.activity-row{
  width: 100%;
}

@media (max-width: 768px ){
  .card-RDV .text-wrapper-2, .card-RDV .div {
    left: 52px;
}

.session-list .card-RDV {
  margin-bottom: 16px;
}

}

@media (min-width: 769px){
  .card-RDV {
    margin: auto;
    width: 416px;
  }
  .card-RDV .text-wrapper-2, .card-RDV .div {
    left: 70px;
}
.card-RDV .vector {
  width: 2px;
  height: 101px;
  top: 15px;
  left: 208px;
}
.card-RDV .text-wrapper-3, .card-RDV .text-wrapper-4 {
  left: 255px;
}
.card-RDV .tag-instance {
  width: 188px;
}
.card-RDV .tag-instance {
  left: 30px !important;
}
}