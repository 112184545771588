#reader{
    width: 90%;
    margin: auto;
    margin-top: 50px;
    padding: 15px;
    border: 1px solid #4282ff00 !important;
    box-shadow: 0px 0px 8px 0px #4282ff;
    border-radius: 6px;
}

.header-mobile-instance {
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
    z-index: 5;
  }
  
   .header-mobile-2 {
    background-image: url(../../../static/img/icon-time-calendar.svg) !important;
    height: 40px !important;
    left: 242px !important;
    position: absolute !important;
    top: 15px !important;
    width: 40px !important;
  }

  .welcome-eapa{
    position : relative;
    margin-top: 150px;
    text-align: center;
  }

  .text-wrapper-5 {
    font-size: 22px;
    font-weight: 500;
  }

  .p{
    margin-top: 0px;
    padding: 15px;
    color: #808080c2;
  }

  .icon-arrow-arrow {
margin: auto;
  }

  #html5-qrcode-button-camera-permission{
    background: #4282ff;
    color: white;
    border: 1px solid #4282ff;
    padding: 7px;
    border-radius: 7px;
  }

  #html5-qrcode-anchor-scan-type-change{
    display: none !important;
  }

  .scan-success{
    text-align: center;
  }

  .scan-success .date{
    font-size: 20px;
    margin-top: 130px;
  }

  .scan-success .image {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .scan-success .session {
    font-size: 19px;
  }

  .scan-success .footer{
    position: fixed;
    bottom: 0px;
  }

  .scan-success .footer .support{
    padding: 15px;
    border-radius: 15px;
    margin: auto;
    background: #ededed;
  }

  .try{
    text-align: center;
   cursor: pointer;
   background: #0d6efd;
   color: white;
   border-radius: 10px;
   padding: 13px;
   font-size: 20px;
    width: 300px;
    margin: auto;
  }

  .scan-success .details{
    text-align: center;
    margin-top: 15px;
  }

  .sessionwrong{
    color: red;
  }

  .scanner{
    text-align: center;
  }

  .scanner .btn {
    margin-top: 75px;
    width: 180px;
    height: 53px;
    font-size: 16px;
  }

  @media (min-width: 769px) {
   .header-mobile-instance {
      width: 100%;
    }
    .header-mobile-2 {
      left: initial !important;
      right: 130px;
  }
}