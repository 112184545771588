.logo-auth{
    width: 200px !important;
    height: 100px !important;
    margin-left: 110px;
    position: relative;
}

.login{
    text-align: center;
}

.error{
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.login .btn{
    margin: auto;
}

@media (max-width: 768px){
    .logo-auth {
        margin-left: 41px;
    }
    .col.forgot-password{
        font-size: 13px;
    }
    .col.justify-content-center, .text-center {
        font-size: 13px;
    }
}

@media (max-width: 990px){
    .logo-auth {
        margin-left: 4px;
    }
}