.bubble-info {
  height: 138px;
  position: relative;
  width: 331px;
}

.bubble-info .frame-2 {
  align-items: flex-start;
  background-color: var(--primary-1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.bubble-info .text-wrapper-4 {
  color: var(--neutralwhite);
  font-family: var(--body-2-bold-font-family);
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 240.44px;
}

.bubble-info .lorem-ipsum-dolor {
  color: var(--neutralwhite);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  position: relative;
  width: 228.86px;
}

.bubble-info .button-bubble-instance {
  left: 283px !important;
  position: absolute !important;
  top: 90px !important;
}

.bubble-info .class-3 {
  background-image: url(../../../../static/img/icon-essential-close-circle-2.svg) !important;
}

.bubble-info .class-4 {
  background-image: url(../../../../static/img/icon-essential-emoji-sad-5.svg) !important;
  left: 12px !important;
  position: relative !important;
  top: 12px !important;
}

.bubble-info .instance-node {
  left: 283px !important;
  position: absolute !important;
  top: 34px !important;
}

.bubble-info .class-5 {
  background-image: url(../../../../static/img/icon-essential-share-3.svg) !important;
  left: 12px !important;
  position: relative !important;
  top: 12px !important;
}
